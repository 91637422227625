<template>
  <div id="task-index">
    <div class="route">
      <!-- <div class="add">
        <Button type="primary"
                @click="add">{{SelectedInd===0?'新增任务':SelectedInd===2?'新增审核':'新增虚拟护栏'}}</Button>
      </div> -->
      <p class="route-title">
        {{ SelectedInd === 0 ? "任务管理" : "虚拟护栏管理" }}
      </p>
      <router-link v-for="(item, ind) in all_navBar"
                   :key="ind"
                   :to="item.path">
        <div :class="SelectedInd != ind ? 'Selected' : ''"
             @click="Selected(ind)">
          <img :src="SelectedInd != ind ? item.img : item.img_press"
               :alt="item.name" />
          {{ item.name }}
        </div>
      </router-link>
    </div>
    <div class="conent">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      all_navBar: [
        {
          name: '任务',
          img: require('@/assets/img/Icon/task_task.png'),
          img_press: require('@/assets/img/Icon/task_task_press.png'),
          path: '/index/task/task',
        },
        {
          name: '审核',
          img: require('@/assets/img/Icon/task_examine.png'),
          img_press: require('@/assets/img/Icon/task_examine_press.png'),
          path: '/index/task/examine',
        },
        {
          name: '虚拟护栏',
          img: require('@/assets/img/Icon/task_notfly.png'),
          img_press: require('@/assets/img/Icon/task_notfly_press.png'),
          path: '/index/task/noFlyZone',
        },
      ],
      SelectedInd: 0,
    };
  },

  methods: {
    //选中nav
    Selected(ind) {
      this.SelectedInd = ind;
    },
    // 上传事件
    // add() {
    //   switch (this.SelectedInd) {
    //     case 0:
    //       this.$_bus.$emit('addTask');
    //       break;
    //     case 2:
    //       this.$_bus.$emit('addExamin');
    //       break;
    //     case 1:
    //       this.$_bus.$emit('addNFzone');
    //       break;
    //   }
    // },
  },

  updated() {
    let activePath = this.$route.path;
    this.all_navBar.forEach((item, ind) => {
      if (item.path == activePath) {
        this.Selected(ind);
      }
    });
  },
};
</script> 

<style  scoped lang="scss">
#task-index {
  width: 100%;
  height: 100%;
  padding: 0.2rem;
  // background-color: #f5f5f5;
  background-color: $xf_hei2;

  display: flex;
  .route {
    font-size: 14px;
    // background-color: $xf_hei2;
    width: 160px;
    height: 100%;
    background-color: $xf_hui1;
    border-right: 1px solid #000;
    font-size: 14px;
    .route-title {
      width: 100%;
      height: 30px;
      line-height: 30px;
      padding-left: 20px;
      background-color: $xf_hui5;
      color: $font_color_1;
    }
    a {
      // display: block;
      // width: 100%;
      color: #4c98ff;
      // margin-top: 21px;
      div {
        display: flex;
        padding-left: 21px;
        align-items: center;
        padding: 15px 10px;
        height: 50px;
        img {
          width: 15px;
          height: 15px;
          margin-right: 11px;
        }
      }
      .Selected {
        color: #888;
        //   background-color: $xf_hui1;
      }
    }

    .add {
      //   position: absolute;
      //   right: 10px;
      padding: 10px 20px;
      button {
        width: 100%;
      }
    }
  }
  .conent {
    width: calc(100% - 160px);
    height: 100%;
    background-color: $xf_hei2;
    overflow-x: auto;
  }
  .conent::-webkit-scrollbar {
    display: none;
  }
}
</style>